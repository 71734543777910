import { useEffect, useState } from 'react';
import { VotesCardBig } from 'components/votes/voteCardBig';
import { Loader } from 'components/loader/loader';
import axios from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { MainLayout } from 'components/layout/mainLayout';
import Breadcrumbs from 'components/breadcrumbs';
import { Link } from 'react-router-dom';
import Arrow from 'assets/images/svg/icon_arrow-up-white.svg';

export const Winners = () => {
	const { id } = useParams();
	const [news, setNews] = useState([]);
	const [loading, setLoading] = useState(true);
	const [keys, setKeys] = useState([]);
	const location = useLocation();

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_BACKEND}/voting/winners`)
			.then((data) => {
				setNews(data.data);
				setLoading(false);
			});
	}, [location, id]);

	const renderBlocks = () => {

		return keys.map((e) => {
			return (		
news[e].map((elem, index) => {
							return <VotesCardBig showLabel={true} key={elem.id} news={elem.voteable} />;
						})
			);
		});
	};

	useEffect(() => {
		news && setKeys(Object.keys(news));
	}, [news]);

	return (
		<MainLayout>
			<section className='news winners' style={{ marginTop: 0 }}>
				<div className='container'>
					<div className='breadscrumb breadscrumb_dark text-page__breadscrumb'>
						<Breadcrumbs />
					</div>
					<h1 className='news__title'>Заявки, набравшие наибольшее количество голосов</h1>
					{loading && keys.length === 0 ? (
						<Loader />
					) : (
						<div className='news__list'>{renderBlocks()}</div>
					)}
				</div>
				<Link
					style={{ marginTop: '10px' }}
					className='nomination__file'
					to={'/voting'}
				>
					Смотреть все заявки
					<img
						src={Arrow}
						style={{
							width: '20px',
							height: '20px',
							transform: 'rotate(90deg)',
						}}
						alt=''
					/>
				</Link>
			</section>
		</MainLayout>
	);
};
