import { useEffect, useState } from 'react';
import { VotesCardBig } from 'components/votes/voteCardBig';
import ReactPaginate from 'react-paginate';
import { Loader } from 'components/loader/loader';
import axios from 'axios';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { MainLayout } from 'components/layout/mainLayout';
import Breadcrumbs from 'components/breadcrumbs';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Arrow from 'assets/images/svg/icon_arrow-up-white.svg';

export const Voiting = () => {
	const { id } = useParams();
	const [news, setNews] = useState([]);
	const [loading, setLoading] = useState(true);
	const [page, setPage] = useState(0);
	const history = useHistory();
	const location = useLocation();
	const [region, setRegion] = useState(null);
	const [regionOpt, setRegionOpt] = useState([]);
	const [nominationFilter, setNominationFilter] = useState(null);

	const nominationFilterOptions = [
		{ value: 0, label: 'Все номинации' },
		{ value: 1, label: 'Лучшее зимнее мероприятие во дворе' },
		{ value: 2, label: 'Лучшая зимняя дворовая инфраструктура' },
		{ value: 3, label: 'Лучшее зимнее оформление двора' },
	];

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_BACKEND}/region/list/app`)
			.then((data) => {
				const tempRegions = data.data.data;
				tempRegions &&
					tempRegions.length > 0 &&
					tempRegions.unshift({ id: '0', name: 'Все регионы' });
				setRegionOpt(tempRegions);
			});
	}, []);

	// useEffect(() => {
	// 	history.location.search === '' && history.push('?page=1');
	// });

	useEffect(() => {
		const getParam = location.search.replace('?page=', '');
		axios
			.get(
				`${process.env.REACT_APP_BACKEND}/nomination?page=${getParam}${
					region ? `&region_id=${region}` : ''
				}${nominationFilter ? `&nomination=${nominationFilter}` : ''}`
			)
			.then((data) => {
				setNews(data.data);
				setPage(getParam);
				setLoading(false);
			});
	}, [location, id, region, nominationFilter]);

	const renderBlocks = () => {
		if (!loading && news.data) {
			return news.data.map((newsItem, index) => {
				return <VotesCardBig key={newsItem.id} news={newsItem} />;
			});
		}
	};

	const handlePageClick = ({ selected }) => {
		if (selected === 0 && !location.search) {
			selected = 1;
		}
		setPage(selected + 1);
		setLoading(true);
		history.push(`?page=${selected + 1}`);
	};
	return (
		<MainLayout>
			<section className='news' style={{ marginTop: 0 }}>
				<div className='container'>
					<div className='breadscrumb breadscrumb_dark text-page__breadscrumb'>
						<Breadcrumbs />
					</div>

					<h1 className='news__title'>Народное голосование</h1>
					<p className='category__text' style={{marginBottom: '0'}}>
						Чтобы принять участие в «Народном голосовании» конкурса, необходимо
						пройти процедуру верификации. Для этого необходимо пройти
						регистрацию на сайте конкурса «Лучший зимний двор России». Вы можете
						отдать свой голос лишь за одного участника в каждой из номинаций.
					</p>

					<div className='calendar__banner' style={{minHeight: 'unset', margin: '32px 0'}}>
						<div className='calendar__banner-text'>
							<span>
              ГОЛОСОВАНИЕ ЗАВЕРШЕНО. ИТОГИ КОНКУРСА БУДУТ ПОДВЕДЕНЫ ПОЗДНЕЕ
							</span>
						</div>
					</div>
					<div className='select_wrap'>
						<Select
							options={regionOpt?.map((e) => {
								return { value: e.id, label: e.name };
							})}
							onChange={(e) => {
								history.push(`?page=1`);
								setRegion(e.value);
							}}
							placeholder={'Выберите регион'}
							className='select__item'
						/>
						<Select
							options={nominationFilterOptions.map((e) => {
								return { value: e.value, label: e.label };
							})}
							onChange={(e) => {
								history.push(`?page=1`);
								setNominationFilter(e.value === 0 ? null : e.label);
							}}
							placeholder={'Выберите номинацию'}
							className='select__item'
						/>
					</div>
					{loading ? (
						<Loader />
					) : (
						<div className='news__list'>{renderBlocks()}</div>
					)}
					{news?.meta?.last_page > 1 && (
						<ReactPaginate
							breakLabel='...'
							nextLabel='>'
							pageRangeDisplayed={3}
							pageCount={news.meta.last_page}
							forcePage={page - 1}
							disableInitialCallback={true}
							onPageChange={handlePageClick}
							previousLabel='<'
							renderOnZeroPageCount={null}
							containerClassName={'pagination'}
						/>
					)}
				</div>
				<Link style={{marginTop:'10px'}} className='nomination__file' to={'/winners'}>
					Смотреть победителей
					<img src={Arrow} style={{width:'20px', height:'20px', transform:'rotate(90deg)'}} alt='' />
        </Link>
			</section>
		</MainLayout>
	);
};
