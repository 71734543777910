import InputMask from 'react-input-mask';

export const InputPhone = (props) => {

	return (
		<InputMask
			mask='+7 (999) 999-99-99'
			value={props.value}
			maskChar={null}
			readOnly={props.readOnly}
			onChange={props.onChange}
			disabled={props.disabled}
			placeholder={'Номер телефона'}
		>
			{(inputProps) => {
				return (
					<input
						{...inputProps}
						type='text'
						disabled={props.disabled}
						name='phone'
						inputMode='numeric'
						className={props.className || 'input'}
					/>
				);
			}}
		</InputMask>
	);
};
