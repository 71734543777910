import { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Loader } from 'components/loader/loader';
import Breadcrumbs from 'components/breadcrumbs';
import { MainLayout } from 'components/layout/mainLayout';
import axios from 'axios';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useAuthState } from 'context';
import Like from 'assets/images/svg/like.svg';
import { SliderModal } from 'components/modals/SliderModal';
import { SubmitModal } from 'components/modals/SubmitModal';
import { ToastContainer } from 'react-toastify';
import { useRef } from 'react';
import { SuccessModal } from 'components/modals/SuccessModal';
import ArrowUp from 'assets/images/svg/icon_arrow-up.svg';
import Mock from 'assets/images/mock.png';
import Winner from 'assets/images/winner.png';
import { Header } from 'components/layout/mainLayout/header';


export const DetailNews = () => {
	const { id } = useParams();

	const [detailNews, setDetailNews] = useState([]);
	const [loading, setLoading] = useState(true);
	const { token } = useAuthState();
	const [isOpen, setIsOpen] = useState(false);
	const [slide, setSlide] = useState(0);
	const [voteOpen, setVoteOpen] = useState(false);
	const [successOpen, setSuccessOpen] = useState(false);
	const history = useHistory();
	const { data } = detailNews;

	const [dragging, setDragging] = useState(false);

	const handleBeforeChange = useCallback(() => {
		setDragging(true);
	}, [setDragging]);

	const handleAfterChange = useCallback(() => {
		setDragging(false);
	}, [setDragging]);

	const handleOnItemClick = useCallback(
		(e) => {
			if (dragging) e.stopPropagation();
		},
		[dragging]
	);

	const settings = {
		className: 'slider variable-width',
		dots: true,
		infinite: true,
		slidesToShow: 1,
		arrows: true,
		slidesToScroll: 1,
		swipeToSlide: true,
		// adaptiveHeight: true,
		beforeChange: (current, next) => [setSlide(next), handleBeforeChange()],
		afterChange: () => handleAfterChange(),
	};

	const slider = useRef();
	useEffect(() => {
		if (token) {
			axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		}
		axios
			.get(`${process.env.REACT_APP_BACKEND}/nomination/${id}`)
			.then((data) => {
				setDetailNews(data.data);
				setVoteOpen(false);
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);


	return (
		<MainLayout>
      <Header className='voiting' />
			<section className='text-page'>
				{loading ? (
					<Loader />
				) : (
					<div className='container text-page__container tab'>
						<div className='breadscrumb breadscrumb_dark text-page__breadscrumb'>
							<Breadcrumbs title={data.nomination} />
						</div>

						<section
							className='details'
							style={{ maxWidth: '1411px', margin: '0 auto' }}
						>
							<form className='profile-form'>
								<div className='profile-form__block'>
									<div
										className='profile-form__col profile-form__row_second'
										style={{ display: 'flex' }}
									>
										<div
											className='profile-form__row'
											style={{ width: '100%', borderBottom: '1px solid black' }}
										>
											<div className='profile-form__input'>
												<label className='profile-form__label'>Регион</label>
												<p className='input'> {data.region_name}</p>
											</div>
											<div
												className='profile-form__input'
											>
												<label
													htmlFor='operation_area'
													className='profile-form__label'
												>
													Населённый пункт
												</label>
												<div className='profile-form__input-wrap'>
													<p className='input'>
														{data.application.municipality || '-'}
													</p>
												</div>
											</div>

											<div className='profile-form__input'>
												<label
													htmlFor='organization_name'
													className='profile-form__label'
												>
													Адрес
												</label>
												<div className='profile-form__input-wrap'>
													<p className='input'>
														{data.application.full_address || '-'}
													</p>
												</div>
											</div>
											<div className='profile-form__input'>
												<label
													htmlFor='operation_period'
													className='profile-form__label'
												>
													Номинация
												</label>
												<div className='profile-form__input-wrap'>
													<p className='input'>
														{data.nomination || '-'}
													</p>
												</div>
											</div>
										
										</div>
										<div
											className='profile-form__row'
											style={{ width: '100%' }}
										>
											<h3 className='profile-form__title'>{data.nomination}</h3>
										</div>

										<div className='profile-form__col'>
											<div className='profile-form__block'>
												<div className='profile-form__row profile-form__row_second'>
													<div className='profile-form__input'>
														{data.images && (
															<Slider ref={slider} {...settings}>
																{data.images?.map((e, i) => (
																	<div
																		key={i}
																		onClickCapture={handleOnItemClick}
																		style={{ width: '100%' }}
																	>
																		<img
																			className='slider_image'
																			src={e.path}
																			alt=''
																			onClick={() => setIsOpen(true)}
																		/>
																		{data.is_winner && (
																			<img
																				src={Winner}
																				className='winnerImg'
																				alt=''
																				width='61'
																				height='79'
																				loading='lazy'
																			/>
																		)}
																	</div>
																))}
															</Slider>
														)}
														{data.images.length === 0 && (
															<div style={{ width: '100%' }}>
																<img
																	className='slider_image'
																	src={Mock}
																	alt=''
																/>
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className='profile-form__col details_col'>
											<div className='profile-form__col'>
												<div className='profile-form__block'>
													<div className='profile-form__row profile-form__row_second'>
														<div
															className='profile-form__input'
															style={{
																display: 'flex',
																flexDirection: 'column',
															}}
														>
															<label
																htmlFor='file'
																className='profile-form__label'
																style={{
																	color: 'black',
																	fontSize: '16px',
																	fontWeight: 'bold',
																}}
															>
																Эссе
															</label>
															<a
																target={'_blank'}
																rel='noreferrer'
																style={{ color: '#01a1fc' }}
																href={data?.essay?.path}
															>
																{data.essay?.name}
															</a>
														</div>
													</div>
												</div>
											</div>
											<div className='profile-form__col contact_person'>
												<div className='profile-form__input'>
													<label
														htmlFor='operation_direction'
														className='profile-form__label'
														style={{
															color: 'black',
															fontSize: '16px',
															fontWeight: 'bold',
														}}
													>
														Контактные данные
													</label>
													<div className='profile-form__input-wrap'>
														<p className='input'>
															{`${data.application.contact_surname} ${data.application.contact_name} ${data.application.contact_patronymic}`}
														</p>
													</div>
												</div>
												
											</div>
											<div
												className='profile-form__col details_col'
												style={{ width: '100%' }}
											>
												<div className='profile-form__col'>
													<div className='profile-form__block'>
														<div className='profile-form__row profile-form__row_second'>
															<div
																className='profile-form__input'
																style={{
																	display: 'flex',
																	flexDirection: 'column',
																}}
															>
																<label
																	htmlFor='file'
																	className='profile-form__label'
																	style={{
																		color: 'black',
																		fontSize: '16px',
																		fontWeight: 'bold',
																	}}
																>
																	Материалы СМИ (фото)
																</label>
																{data.media_files?.map((e) => (
																	<a
																		target={'_blank'}
																		rel='noreferrer'
																		style={{ color: '#01a1fc' }}
																		href={e.path}
																		key={e.id}
																	>
																		{e.name}
																	</a>
																))}
																{data.media_files.length === 0 && '-'}
															</div>
														</div>
													</div>
												</div>
												<div className='profile-form__col contact_person'>
													<div
														className='profile-form__input'
														style={{
															display: 'flex',
															flexDirection: 'column',
														}}
													>
														<label
															htmlFor='operation_direction'
															className='profile-form__label'
															style={{
																color: 'black',
																fontSize: '16px',
																fontWeight: 'bold',
															}}
														>
															Материалы СМИ (ссылки)
														</label>
														{data.media?.map((e, i) => (
															<a
																target={'_blank'}
																rel='noreferrer'
																href={e}
																style={{ color: '#01a1fc' }}
																key={i}
															>
																{e}
															</a>
														))}
														{!data.media && '-'}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
							<div className='likes'>
								<button
									className='footer__btn js-scroll-to-top'
									type='button'
									onClick={() => history.goBack()}
								>
									<img
										src={ArrowUp}
										style={{ transform: 'rotate(270deg)' }}
										className='footer__arrow-up'
										alt='icon'
									/>
									Назад
								</button>
								<div className='likes__wrap'>
									<img src={Like} alt='' />
									<span className='like_text'>{data.votes_count}</span>
								</div>
							</div>
						</section>
					</div>
				)}

				{data?.images && (
					<SliderModal
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						images={data?.images}
						slide={slide}
						slider={slider}
					/>
				)}
			</section>
			<SubmitModal
				isOpen={voteOpen}
				setIsOpen={setVoteOpen}
				id={data?.id}
				token={token}
				setDetailNews={setDetailNews}
				setSuccessOpen={setSuccessOpen}
			/>
			<SuccessModal
				isOpen={successOpen}
				setIsOpen={setSuccessOpen}
				votesCount={data?.votes_count}
			/>
			<ToastContainer />
		</MainLayout>
	);
};
