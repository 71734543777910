import React from 'react';
import Modal from 'react-modal';
import { useAuthDispatch } from 'context';
import axios from 'axios';
import { updateUser } from 'context/actions';
import { ToastContainer } from 'react-toastify';
import { errorRegisterToast } from 'components/notifications';

Modal.setAppElement('#root');

export const SubmitModal = ({
	isOpen,
	setIsOpen,
	id,
	token,
	setDetailNews,
  setSuccessOpen
}) => {
	const clearModalState = () => {
		setIsOpen(false);
	};

	const customStyles = {
		overlay: {
			backgroundColor: 'rgb(0,0,0,0.5)',
			display: 'flex',
			alignItems: 'center',
		},
		content: {
			position: 'unset',
			backgroundColor: 'white',
			height: 'fit-content',
			margin: '0 auto',
			textAlign: 'center',
			top: '0',
			bottom: '0',
			transform: 'unset',
			padding: '25px',
      maxWidth: '480px'
		},
	};

	const closeModal = () => clearModalState();
	const dispatch = useAuthDispatch();

	const submit = (id, token) => {
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		const headers = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		};
		axios
			.post(`${process.env.REACT_APP_BACKEND}/voting/vote/${id}`, {
				headers: headers,
			})
			.then((data) => {
				updateUser(dispatch, data.data.data?.user);
				closeModal();
				setDetailNews({ data: data.data.data?.voteable });
        setSuccessOpen(true)
			})
			.catch((e) => {
        const message =
        'Действие недоступно. Голосование завершено 04.09.2022 в 23:59';
      errorRegisterToast(message);
      closeModal();
				console.log(e);
			});
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			className={'submit_modal'}
			style={customStyles}
			contentLabel='Example Modal'
			shouldCloseOnOverlayClick={false}
		>
			<div>
				<h2>Внимание</h2>
				<p>
        Вы можете отдать свой голос лишь <b>за одного</b> участника <b>в каждой из номинаций.</b>
				</p>
				<div className='submit_modal_btn_wrap'>
					<button
						style={{ padding: '10px' }}
						className='main__btn button modal__btn modal__btn-white'
						onClick={() => closeModal()}
					>
						Отмена
					</button>
					<button
						style={{ padding: '10px' }}
						className='main__btn button modal__btn modal__btn-red'
						onClick={() => submit(id, token)}
					>
						Проголосовать
					</button>
				</div>
			</div>
			<ToastContainer />
		</Modal>
	);
};
