export const ShowNomination = ({ nomination, region, loading }) => {
	const selectedRegion =
		!loading &&
		region.data &&
		region.data.filter(function (item) {
			return item.id === nomination[0].region_id;
		});

	return (
		<>
			{!loading && (
				<>
					<section className='profile'>
						<h3
							className='profile__title'
							style={{
								textAlign: 'center',
								color: 'red',
                border:'0'
							}}
						>
							Ваша заявка была принята, большое спасибо за участие !
						</h3>
						<form
							className='profile__form js-registration-form'
							style={{ margin: '20px 0' }}
						>
							<div className='profile__form-row'>
								<div className='profile__form-col personal '>
									<h3 className='profile__title'>Адрес дома</h3>
									<div className='profile__input-wrapper'>
										<div className='profile__input-block'>
											<label>Субъект РФ</label>
											<input
												className='input'
												id='address'
												name='address'
												type='text'
												placeholder='Адрес'
												value={selectedRegion[0].name}
												disabled
											/>
										</div>
										<div className='profile__input-block'>
											<label htmlFor='address'>
												Муниципальное образование{' '}
											</label>

											<input
												className='input'
												id='address'
												name='address'
												type='text'
												placeholder='Адрес'
												value={nomination[0].municipality}
												disabled
											/>
											<span className='profile__error'>
												Поле не должно быть пустым
											</span>
										</div>
										<div className='profile__input-block'>
											<label htmlFor='address'>Адрес </label>

											<input
												className='input'
												id='address'
												name='address'
												type='text'
												placeholder='Адрес'
												value={nomination[0].full_address}
												disabled
											/>
											<span className='profile__error'>
												Поле не должно быть пустым
											</span>
										</div>
									</div>
								</div>
								<div className='profile__form-col personal '>
									<h3 className='profile__title'>Контактное лицо</h3>
									<div className='profile__input-wrapper'>
										<div className='profile__input-block'>
											<label>Фамилия</label>
											<input
												className='input'
												id='address'
												name='address'
												type='text'
												placeholder='Адрес'
												value={nomination[0].contact_surname}
												disabled
											/>
										</div>
										<div className='profile__input-block'>
											<label htmlFor='address'>Имя </label>

											<input
												className='input'
												id='address'
												name='address'
												type='text'
												placeholder='Адрес'
												value={nomination[0].contact_name}
												disabled
											/>
											<span className='profile__error'>
												Поле не должно быть пустым
											</span>
										</div>
										<div className='profile__input-block'>
											<label htmlFor='address'>Отчество </label>

											<input
												className='input'
												id='address'
												name='address'
												type='text'
												placeholder='Адрес'
												value={nomination[0].contact_patronymic}
												disabled
											/>
											<span className='profile__error'>
												Поле не должно быть пустым
											</span>
										</div>
									</div>
								</div>
								<div className='profile__form-col personal '>
									<div className='profile__input-wrapper'>
										<div className='profile__input-block'>
											<label>Телефон</label>
											<input
												className='input'
												id='contact_phone'
												name='contact_phone'
												type='text'
												placeholder='Адрес'
												value={nomination[0].contact_phone}
												disabled
											/>
										</div>
										<div className='profile__input-block'>
											<label htmlFor='address'>Алрес электронной почты </label>

											<input
												className='input'
												id='address'
												name='address'
												type='text'
												placeholder='Адрес'
												value={nomination[0].contact_email}
												disabled
											/>
											<span className='profile__error'>
												Поле не должно быть пустым
											</span>
										</div>
									</div>
								</div>
								<div className='profile__form-col personal '>
									<h3 className='profile__title'>Номинаци</h3>
									{nomination[0].nominations?.map((e, i) => {
										return (
											<div key={i} className='profile__input-wrapper'>
												<div className='profile__input-block'>
													<label htmlFor='naming'>Номинация</label>

													<input
														className='input'
														id='naming'
														name='naming'
														type='text'
														placeholder='Наименование'
														value={e.nomination}
														disabled
													/>
												</div>

												<div
													className='profile__input-block'
													style={{ marginRight: '5%' }}
												>
													<label htmlFor='naming'>Эссе</label>

													<span style={{ wordBreak: 'break-all' }}>
														{e.essay.name}
													</span>
												</div>
												<div className='profile__input-block'>
													<label htmlFor='smi'>
														{' '}
														Фотографии (файлы jpg, png)
													</label>
													<div
														className='profile__input-block-wrap'
														style={{
															flexDirection: 'column',
															alignItems: 'unset',
														}}
													>
														{e.images && e.images.length > 0 ? e.images?.map((e) => {
															return <span>{e.name}</span>;
														}) : <span>-</span>}
													</div>
												</div>
												<div className='profile__input-block'>
													<label htmlFor='smi'>
														{' '}
														Материалы СМИ (ссылки на публикации)
													</label>
													<div
														className='profile__input-block-wrap'
														style={{
															flexDirection: 'column',
															alignItems: 'unset',
														}}
													>
														{e.media && e.media.length > 0 ? (
															e.media?.map((e) => {
																return <span>{e}</span>;
															})
														) : (
															<span>-</span>
														)}
													</div>
												</div>
												<div className='profile__input-block'>
													<label htmlFor='smi'>
														{' '}
														Материалы СМИ (ссылки на публикации)
													</label>
													<div
														className='profile__input-block-wrap'
														style={{
															flexDirection: 'column',
															alignItems: 'unset',
														}}
													>
														{e.media_files && e.media_files.length > 0 ? e.media_files?.map((e) => {
															return <span>{e.name}</span>;
														}) : <span>-</span>}
													</div>
												</div>
											</div>
										);
									})}

									{/* <div className='profile__input-block'>
										<label htmlFor='smi'>Материалы СМИ (до 10 штук)</label>
										<div
											className='profile__input-block-wrap'
											style={{ flexDirection: 'column', alignItems: 'unset' }}
										>
											{nomination[0].medias.map((e) => {
												return <span>{e.name}</span>;
											})}
										</div>
									</div> */}

									{/* <div
										className='profile__input-block'
										style={{ flexDirection: 'column' }}
									>
										<label htmlFor='photo'>
											Фотографии (не более 10 шт. и не менее 150 Кб каждое)
										</label>
										{nomination[0].images.map((e) => {
											return <span>{e.name}</span>;
										})}
									</div> */}
								</div>
							</div>
						</form>
					</section>
				</>
			)}
		</>
	);
};
