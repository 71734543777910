import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useEffect } from 'react';
import { useAuthState } from 'context';
import { MainLayout } from 'components/layout/mainLayout';
import axios from 'axios';
import { useState } from 'react';
import { Loader } from 'components/loader/loader';
import { PersonalDetails } from 'components/forms/profile/personalDetails';
import { updateUser } from 'context/actions';
import { useAuthDispatch } from 'context';
import { Header } from 'components/layout/mainLayout/header';
import Breadcrumbs from 'components/breadcrumbs';
import { ShowNomination } from 'components/forms/profile/showNomination';

export const Profile = () => {
	const { token } = useAuthState();
	const [loading, setLoading] = useState(true);
	const [region, setRegion] = useState([]);
	const dispatch = useAuthDispatch();
	const [app, setApp] = useState([]);

	useEffect(() => {
		axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		axios.get(`${process.env.REACT_APP_BACKEND}/user/profile`).then((data) => {
			data.data.data.application && setApp([data.data.data.application]);
			updateUser(dispatch, data.data.data).then(() => setLoading(false));
		});
	}, [token, dispatch]);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_BACKEND}/region/list`)
			.then((data) => setRegion(data.data));
	}, []);

	return (
		<MainLayout>
			{loading ? (
				<Loader />
			) : (
				<section className='profile'>
					<Header />
					<div className='container profile__container tab'>
						<Breadcrumbs />
						{app?.length > 0 ? (
							<Tabs selectedTabClassName='active'>
								<TabList className='profile__tabs'>
									<Tab className='profile__tab'>Заявка на участие</Tab>
									<Tab className='profile__tab'>Личные данные</Tab>
								</TabList>

								<TabPanel>
									<div id='content1' className='info'>
										<ShowNomination
											nomination={app}
											region={region}
											loading={loading}
										/>
									</div>
								</TabPanel>
								<TabPanel>
									<div id='content2' className='perosnal' style={{ maxWidth: '1120px', margin: '0 auto' }}>
										<PersonalDetails region={region} loading={loading} />
									</div>
								</TabPanel>
							</Tabs>
						) : (
							<div id='content2' className='perosnal' style={{ maxWidth: '1120px', margin: '0 auto' }}>
                <h3 style={{textAlign:'center'}}>Личные данные</h3>
								<PersonalDetails region={region} loading={loading} />
							</div>
						)}
</div>
				</section>
			)}
		</MainLayout>
	);
};
