import Phone from 'assets/images/svg/icon_phone.svg';
import { Formik, Form, Field } from 'formik';
import InputMask from 'react-input-mask';
import { TimedBtn } from 'components/timer';
import { AuthLayout } from 'components/layout/authLayout';
import { useAuthDispatch } from '../../../context';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { loginUser } from 'context/actions';
import { errorSmsToast } from 'components/notifications';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useState } from 'react';
const Input = (props) => (
	<InputMask
		mask='999999'
		value={props.value}
		maskChar={null}
		onChange={props.onChange}
		placeholder={'Смс код'}
	>
		{(inputProps) => {
			return (
				<Field
					{...inputProps}
					type='text'
					autoComplete='one-time-code'
					inputMode='numeric'
					name='code'
					className={props.className}
				/>
			);
		}}
	</InputMask>
);

export const SmsStep = ({
	phone,
	isNew,
	setStep,
  setIsnew,
  nextStep,
	expired,
	setExpired,
	smsError,
}) => {
	const hiddenPhone = phone.slice(0, -4) + '****';
	const dispatch = useAuthDispatch();
	const history = useHistory();
const [ trigger , setTrigger ] = useState(false)
	const smsCode = () => {
		return axios
			.post(`${process.env.REACT_APP_BACKEND}/auth/authenticate`, {
				phone,
			})
			.then((e) => [setExpired(5), setTrigger(!trigger)]);
	};

	useEffect(() => {
		smsError && errorSmsToast();
	}, [smsError]);

	return (
		<AuthLayout>
			<section className='authorization'>
				<div className='authorization__container'>
					<div className='authorization__step1'>
						<p className='authorization__desc'>
							Введите полученный в СМС код на Номер {hiddenPhone}.
						</p>
						<Formik
							initialValues={{ code: '' }}
							validate={(values) => {
								const errors = {};
								if (!values.code) {
									errors.code = 'Обязательное поле';
								}
								if (values.code && values.code.length < 6) {
									errors.code = 'Не правильный формат кода';
								}

								return errors;
							}}
							onSubmit={async (values, { setSubmitting, setFieldError }) => {
                try {
									if (!isNew) {
										return await loginUser(dispatch, {
											code: values.code,
											phone: phone,
										})
                    .then((response) => {
                      if (response.is_new) {
                        setIsnew(true);
                        return nextStep();
                      }
                      response.user
                        ? history.push('/profile')
                        : setFieldError('code', response.message);
                    })
                    .catch((e) => console.log(e));

									}
									return await loginUser(dispatch, {
										code: values.code,
										phone: phone,
									})
										.then((response) => {
											response.user
												? setStep(2)
												: setFieldError('code', response.message);
										})
										.catch((e) => {
											setFieldError(
												'code',
												'Телефон пользователя или код не совпадают.'
											);
										});
								} catch (error) {
									console.log(error);
								}
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								isValid,
								handleSubmit,
								isSubmitting,
							}) => (
								<Form
									className='authorization__form authorization__form_3 js-authorization2step-form'
									onSubmit={handleSubmit}
									autoComplete={'off'}
								>
									<div className='authorization__input-block'>
										<label htmlFor='phone'>6 символов из смс</label>
										<Input
											autoComplete={'off'}
											className={errors.code ? 'input error' : 'input'}
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.code}
										/>
										<img src={Phone} alt='phone' />
										<span className='authorization__error'>
											{errors.code && touched.code && errors.code}
										</span>
									</div>
									<div className='authorization__repeat-btn'>
										<TimedBtn initialMinute={expired} trigger={trigger} onClick={smsCode} />
									</div>
									<div>
										<button
											className='authorization__btn-submit button'
											type='submit'
											disabled={!isValid}
										>
											Войти
										</button>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</div>
				<ToastContainer />
			</section>
		</AuthLayout>
	);
};
