import { Link } from 'react-router-dom';
import Mock from 'assets/images/mock.png';
import Like from 'assets/images/svg/like.svg';
import LikeGrey from 'assets/images/svg/like-grey.svg';
import { useAuthState } from 'context';
import Winner from 'assets/images/winner.png';

export const VotesCardBig = ({ news, showLabel = false }) => {
	const { userDetails } = useAuthState();

	const image =
		news.images && news.images?.length > 0 ? news.images[0].path : Mock;
console.log(news)
	return (
		<div className='news__item'>
        {showLabel && <h3 style={{margin: '10px 0'}}>{news.nomination}</h3>}
			<Link to={`/voting/${news.id}`} className='new new_big'>
				<div className='new__img-block'>
					<img src={image} alt='' width='544' height='225' loading='lazy' />

					{news.is_winner && (
						<img
							src={Winner}
							className='winnerImg'
							alt=''
							width='61'
							height='79'
							loading='lazy'
						/>
					)}
				</div>
				<div className='new__text-block'>
					
					<div className='votecard__text_wrap'>
            <span>Адрес</span>
            <p className='new__text'>{news.application.full_address}</p>
						<span>
						Номинация
						</span>
            <p className='new__text'>{news.nomination}</p>
					</div>
					<span className='new__date'>
						<img
							src={
								userDetails.voted_nominations?.includes(news.id)
									? Like
									: LikeGrey
							}
							alt=''
						/>
						{news.votes_count ? news.votes_count : 0}
					</span>
				</div>
			</Link>
		</div>
	);
};
