import { Main } from 'pages/main';
import { DetailNews } from 'templates/detailedNew';
import { Login } from 'pages/login/';
import { Profile } from 'pages/profile';
import { Voiting } from 'pages/voting';
import { Winners } from 'pages/winners';
export const routes = [
	{
		path: '/login',
		component: Login,
		isPrivate: false,
		breadcrumb: 'Авторизация',
	},
	{
		path: '/news',
		component: Main,
		isPrivate: false,
		breadcrumb: null,
	},
	{
		path: '/',
		component: Main,
		isPrivate: false,
		breadcrumb: 'Главasdsadsaная',
	},
	{
		path: '/voting/:id',
		component: DetailNews,
		isPrivate: false,
		breadcrumb: null,
	},
	{
		path: '/profile',
		component: Profile,
		isPrivate: true,
		breadcrumb: 'Профиль',
	},
	{
		path: '/voting',
		component: Voiting,
		isPrivate: false,
		breadcrumb: null,
	},
  {
		path: '/winners',
		component: Winners,
		isPrivate: false,
		breadcrumb: null,
	},
];
