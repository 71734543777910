import { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { InputPhone } from 'components/inputs/phoneInput';
import { AuthLayout } from 'components/layout/authLayout';
import axios from 'axios';
import { Loader } from 'components/loader/loader';
import { FormSelect } from 'components/inputs/selectField';
import { validationProfile } from '../validation';
import { DateInput } from 'components/inputs/birthdayInput';
import { useHistory } from 'react-router-dom';
import { formatDate2 } from 'components/utils';
import { useAuthState } from 'context';
import { errorRegisterToast } from 'components/notifications';
import { ToastContainer } from 'react-toastify';
export const ProfileStep = ({ phone }) => {
	const [selectedRegionOption, setSelectedRegionOption] = useState(null);
	const [selectedDisctrictOption, setSelectedDisctrictOption] = useState(null);
	const [regionOptions, setRegionOptions] = useState(null);
	const [loading, setLoading] = useState(true);
	const [region, setRegion] = useState([]);
	const [date, setDate] = useState(null);
	const history = useHistory();
	const { token } = useAuthState();

	useEffect(() => {
		setLoading(true);
		axios
			.get(`${process.env.REACT_APP_BACKEND}/region/list`)
			.then((data) => setRegion(data.data))
			.then((data) => setLoading(false));
	}, []);

	useEffect(() => {
		selectedRegionOption &&
			axios
				.get(
					`${process.env.REACT_APP_BACKEND}/district/list/${selectedRegionOption}`
				)
				.then((data) => {
					setRegionOptions(data.data);
					setLoading(false);
				});
	}, [selectedRegionOption]);

	const getRegionOpt = () => {
		if (regionOptions)
			return regionOptions.data.map((e) => {
				return { value: e.id, label: e.name };
			});
	};

	const initialValues = {
		surname: '',
		name: '',
		phone: phone,
		email: '',
		birthday: '',
		district_id: '',
		region_id: '',
		address: '',
		personal_data_confirmation: false,
	};

	return (
		<AuthLayout>
			{loading ? (
				<Loader />
			) : (
				<section className='authorization'>
					<div className='authorization__container'>
						<div className='authorization__step1'>
							<Formik
								initialValues={{ ...initialValues }}
								validate={(values) => {
									return validationProfile(values);
								}}
								onSubmit={(values) => {
									const prepData = {
										...values,
										birthday: formatDate2(date),
										region_id: selectedRegionOption,
										district_id: selectedDisctrictOption,
									};
									axios.defaults.headers.common[
										'Authorization'
									] = `Bearer ${token}`;
									axios
										.post(
											`${process.env.REACT_APP_BACKEND}/auth/registration`,
											{
												...prepData,
											}
										)
										.then((data) => {
											return history.push('/profile');
										})
										.catch((e) => {
											errorRegisterToast(e.response.data.message);
										});
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
                  setFieldValue
								}) => (
									<Form
										className='authorization__form authorization__form_reg js-registration-form'
										onSubmit={handleSubmit}
									>
										<div className='authorization__input-block'>
											<label htmlFor='surname'>Фамилия*</label>

											<Field
												className={errors.surname ? 'input error' : 'input'}
												onChange={handleChange}
												value={values.surname}
												id='surname'
												name='surname'
												type='text'
												placeholder='Фамилия'
											/>
											<span className='authorization__error'>
												{errors.surname && touched.surname && errors.surname}
											</span>
										</div>
										<div className='authorization__input-block'>
											<label htmlFor='name'>Имя*</label>

											<Field
												onChange={handleChange}
												value={values.name}
												className={errors.name ? 'input error' : 'input'}
												id='name'
												name='name'
												type='text'
												placeholder='Имя'
											/>
											<span className='authorization__error'>
												{errors.name && touched.name && errors.name}
											</span>
										</div>
										<div className='authorization__input-block'>
											<label htmlFor='patronymic'>Отчество (если есть)</label>

											<Field
												onChange={handleChange}
												value={values.patronymic}
												className='input'
												id='patronymic'
												name='patronymic'
												type='text'
												placeholder='Отчество'
											/>
											<span className='authorization__error'>
												{errors.patronymic}
											</span>
										</div>

										<div className='authorization__input-block'>
											<label htmlFor='birthday'>Дата рождения*</label>

											<Field
												className={
													errors.birthday
														? 'input js-datapicker error'
														: 'input js-datapicker'
												}
												id='birthday'
												component={DateInput}
												clearIcon={null}
												calendarIcon={null}
												monthPlaceholder={'ММ'}
												dayPlaceholder={'ДД'}
												yearPlaceholder={'ГГГГ'}
												onChange={setDate}
												value={date}
												name='birthday'
												type='text'
												placeholder='ДД.ММ.ГГГГ'
											/>
											<span className='authorization__error'>
												{errors.birthday && touched.birthday && errors.birthday}
											</span>
										</div>
										<div className='authorization__input-block'>
											<label htmlFor='phone'>Номер телефона*</label>

											<InputPhone
												onChange={handleChange}
												onBlur={handleBlur}
												readOnly
												className={errors.phone ? 'input error' : 'input'}
												disabled={true}
												value={values.phone}
											/>
										</div>
										<div className='authorization__input-block'>
											<label htmlFor='email'>Адрес электронной почты*</label>

											<Field
												className={errors.email ? 'input error' : 'input'}
												id='email'
												name='email'
												onChange={handleChange}
												value={values.email}
												type='text'
												placeholder='Email'
											/>
											<span className='authorization__error'>
												{errors.email && touched.email && errors.email}
											</span>
										</div>

										<div className='authorization__input-block'>
											<label>Регион*</label>
											<Field
												name='region_id'
												component={FormSelect}
												className={errors.region_id ? 'error' : ''}
												value={selectedRegionOption}
												placeholder='Выберите'
												onChange={(value) => [setSelectedRegionOption(value), setFieldValue('district_id', 0)]}
												options={region.data.map((e) => {
													return { value: e.id, label: e.name };
												})}
											/>
											<span className='authorization__error'>
												{errors.region_id &&
													touched.region_id &&
													errors.region_id}
											</span>
										</div>
										<div className='authorization__input-block'>
											<label htmlFor='district_id'>Район*</label>
											<Field
												name='district_id'
												className={errors.district_id ? 'error' : ''}
												component={FormSelect}
												placeholder='Выберите'
												options={getRegionOpt()}
												value={selectedDisctrictOption}
												onChange={setSelectedDisctrictOption}
											/>
											<span className='authorization__error'>
												{errors.district_id &&
													touched.district_id &&
													errors.district_id}
											</span>
										</div>
										<div className='authorization__input-block'>
											<label htmlFor='address'>Населенный пункт*</label>

											<Field
												className={errors.address ? 'input error' : 'input'}
												id='address'
												onChange={handleChange}
												value={values.address}
												name='address'
												type='text'
												placeholder='Населенный пункт'
											/>
											<span className='authorization__error'>
												{errors.address && touched.address && errors.address}
											</span>
										</div>



										<div className='authorization__btn-block'>
											<button
												className='authorization__btn-submit button'
												type='submit'
											>
												Зарегистрироваться
											</button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
					<ToastContainer />
				</section>
			)}
		</AuthLayout>
	);
};
