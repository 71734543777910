import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { NavLink } from 'react-router-dom';
import IconLeftArrowWhite from 'assets/images/svg/icon_left-arrow-white.svg';
import IconLeftArrow from 'assets/images/svg/icon_left-arrow.svg';
const Breadcrumbs = ({ breadcrumbs, title }) => {
	return (
		<div className='breadscrumb'>
			<img
				src={
					breadcrumbs[0].location.pathname === '/login'
						? IconLeftArrowWhite
						: IconLeftArrow
				}
				alt={'ico'}
			/>
			{breadcrumbs.map(({ match, breadcrumb }, index) => {

				return (
					<NavLink
						key={match.url}
						className={
							index + 1 !== breadcrumbs.length ? 'crumb_first' : 'crumb_item'
						}
						to={match.url}
					>
						{match.url === `/voting/${breadcrumb.props.children}`
							? title.substring(0, 35) + '...'
							: breadcrumb}{' '}
						<span>/&nbsp;</span>
					</NavLink>
				);
			})}
		</div>
	);
};

export default withBreadcrumbs(
	[
		{
			path: '/',
			breadcrumb: 'Главная',
		},
		{
			path: '/login',
			breadcrumb: 'Авторизация',
		},
		{
			path: '/profile',
			breadcrumb: 'Профиль',
		},
		{
			path: '/voting',
			breadcrumb: 'Народное голосование',
		},
    {
			path: '/winners',
			breadcrumb: 'Победители',
		},
	],
	{ excludePaths: ['/news', '/news/:id'] }
)(Breadcrumbs);
