import Logo from 'assets/images/logo.svg';
import Person from 'assets/images/svg/icon_person.svg';
import Login from 'assets/images/svg/icon_in.svg';
import { Link } from 'react-router-dom';
import { useAuthState, useAuthDispatch, logout } from 'context';
import Avatar from 'assets/images/svg/avatar.svg';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'components/utils';

export const Header = (props) => {
	const { userDetails } = useAuthState();
	const history = useHistory();
	const dispatch = useAuthDispatch();

	const handleLogout = () => {
		logout(dispatch);
		history.push('/login');
	};

	return (
		<header id='header' className={props.className}>
			<div className='container'>
				<Link className='logo_link' to={'/'}>
					<img src={Logo} alt={'logo'} />
				</Link>
				{history.location.pathname !== '/' && !isMobile && (
					<p className='header_text'>
						<span>Лучший зимний двор России</span>
						<br></br>
						<span>
							Всероссийский Конкурс лучших практик благоустройства городской
							среды среди граждан и юридических лиц России
						</span>
					</p>
				)}
				{userDetails.name ? (
					<div className='details'>
						<Link className='' to={'/profile'}>
							<div>
								<img src={Avatar} alt='ava' />
							</div>
							<div className='details_text'>
								<span>{userDetails.surname}</span>
								<br></br>
								<span>
									{userDetails.name} {userDetails.patronymic}
								</span>
							</div>
						</Link>
						<img
							className='main__icon-in'
							onClick={handleLogout}
							src={Login}
							alt='person'
						/>
					</div>
				) : (
					<Link className='main__btn-in' to={'/login'}>
						<img className='main__icon-person' src={Person} alt='person' />
						Вход / Регистрация
						<img className='main__icon-in' src={Login} alt='person' />
					</Link>
				)}
			</div>
		</header>
	);
};
