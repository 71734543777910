import React from 'react';
import Modal from 'react-modal';
import Mock from 'assets/images/mock.png';
import Close from 'assets/images/svg/close.svg';
import Arrow from 'assets/images/svg/icon_arrow-up-white.svg';

Modal.setAppElement('#root');

export const SliderModal = ({ isOpen, setIsOpen, images, slide, slider }) => {
	const clearModalState = () => {
		setIsOpen(false);
	};

	const customStyles = {
		overlay: {
			backgroundColor: 'rgb(0,0,0,0.5)',
			display: 'flex',
			alignItems: 'center',
		},
		content: {
			position: 'relative',
			backgroundColor: 'white',
			// height: '90%',
			margin: '0 auto',
			textAlign: 'center',
			top: '0',
			bottom: '0',
			transform: 'unset',
			padding: '0',
			width: 'auto',
			overflow: 'unset',
			inset: '0',
			border: 'unset',

		},
	};

	const closeModal = () => clearModalState();

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			contentLabel='Example Modal'
			style={customStyles}
      className={'slider_modal'}
			shouldCloseOnOverlayClick={false}
		>
      <img src={Arrow} alt='' onClick={() => slider.current.slickPrev()} className='slide_prev' />
			<img
				src={Close}
				className={'modal_close'}
				alt='close'
				onClick={closeModal}
			/>

			<img
				src={
					images.length > 0 && images[slide].path ? images[slide].path : Mock
				}
				alt=''
				className='modal_img'
			/>
      <img src={Arrow} alt='' onClick={() => slider.current.slickNext()} className='slide_next' />

		</Modal>
	);
};
