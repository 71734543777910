import React from 'react';
import { useState, useEffect } from 'react';

export const TimedBtn = (props) => {
	const { initialMinute = 0, initialSeconds = 0 } = props;
	const [minutes, setMinutes] = useState(initialMinute);
	const [seconds, setSeconds] = useState(initialSeconds);

	useEffect(() => {
		setMinutes(initialMinute);
	}, [initialMinute]);

  useEffect(() => {
    setMinutes(5)
  }, [props.trigger])

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	});

	return (
		<>
			{minutes === 0 && seconds === 0 ? (
				<button
					className='authorization__repeat-btn'
					type='button'
					onClick={props.onClick}
				>
					Запросить код
				</button>
			) : (
				<span>
					Повторно запросить код можно через {minutes}:
					{seconds < 10 ? `0${seconds}` : seconds}
				</span>
			)}
		</>
	);
};
