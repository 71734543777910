import { useEffect, useState } from 'react';
import { VotesCardBig } from './voteCardBig';
import Arrow from 'assets/images/svg/icon_arrow-up-white.svg';
import { Loader } from 'components/loader/loader';
import axios from 'axios';
import { Link } from 'react-router-dom';
// import { useMatchMedia } from 'hooks/useMatchMedia';

export const MainVotes = () => {
	const [news, setNews] = useState([]);
	const [keys, setKeys] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {

		axios.get(`${process.env.REACT_APP_BACKEND}/voting/winners`).then((data) => {
			setNews(data.data);
			setLoading(false);
		});
		
	}, []);


	const renderBlocks = () => {

		return keys.map((e) => {
			return (

						news[e].map((elem, index) => {
							return <VotesCardBig showLabel={true} key={elem.id} news={elem.voteable} />;
						})

			);
		});
	};

	useEffect(() => {
		news && setKeys(Object.keys(news));
	}, [news]);

	return (
		<section className='news main'>
			<div className='container'>
				<div className='news__title title'>Заявки, набравшие наибольшее количество голосов</div>
				{loading ? (
					<Loader />
				) : (
					<div className='news__list'>{renderBlocks()}</div>
				)}
				<Link className='nomination__file' to={'/voting'}>
        Смотреть все заявки
					<img src={Arrow} style={{width:'20px', height:'20px', transform:'rotate(90deg)'}} alt='' />
				</Link>
			</div>
		</section>
	);
};
