export const formatDate = (dateString) => {
	return new Date(dateString).toLocaleString('ru', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timezone: 'UTC',
		hour: 'numeric',
		minute: 'numeric',
	});
};

export const formatDate2 = (dateString) => {
	return new Date(dateString).toLocaleString('ru', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
	});
};

export const scroll = (selector) => {
	const section = document.querySelector(`${selector}`);
	return section.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

export const checkWindow = (maxWidthPixel) =>
	typeof window !== 'undefined' &&
	window.matchMedia(`(max-width: ${maxWidthPixel}px)`).matches;

export const isMobile = checkWindow(1024);

export const isPhone = checkWindow(768);


export const rebuildData = (values) => {
  let formData = new FormData();
  formData.append(
    `full_address`,
    values.full_address
  );
  formData.append(
    `contact_phone`,
    values.contact_phone
  );
  formData.append(
    `contact_email`,
    values.contact_email
  );
  formData.append(
    `municipality`,
    values.municipality
  );
  formData.append(
    `contact_name`,
    values.contact_name
  );
  formData.append(
    `contact_surname`,
    values.contact_surname
  );
  formData.append(
    `contact_patronymic`,
    values.contact_patronymic
  );

  Object.keys(values).forEach((key) => {
    const indexes = Object.keys(values.nominations);
    if (key === 'region_id') {
      return formData.append(key, values[key].value);
    }
   
    if (key === 'nominations') {
      // eslint-disable-next-line array-callback-return
      indexes.map((e, i) => {
        if (values.nominations[e].nomination.label) {
          formData.append(
            `nominations[${e}][nomination]`,
            values.nominations[e].nomination?.label
          );
          formData.append(
            `nominations[${e}][essay]`,
            values.nominations[e].essay
          );
          if (values.nominations[e].images.length > 0)
            values.nominations[e].images.forEach((elem, index) => {
              formData.append(
                `nominations[${e}][images][${index}]`,
                elem
              );
            });
            if (values.nominations[e].media_files.length > 0)
            values.nominations[e].media_files.forEach((elem, index) => {
              formData.append(
                `nominations[${e}][media_files][${index}]`,
                elem
              );
            });
            if (values.nominations[e].media.length > 0)
            values.nominations[e].media.forEach((elem, index) => {
              formData.append(
                `nominations[${e}][media][${index}]`,
                elem
              );
            });
        }
      });
    }
  });
  return formData;
};
