import { Header } from './header';
import { Footer } from './footer';
import { useLocation } from 'react-router-dom';

export const MainLayout = ({ children }) => {
	const location = useLocation();
const showHeader = ['/voting','/winners']
	return (
		<>
			{showHeader.includes(location.pathname) && <Header className='voiting' />}

			<main>{children}</main>
			<Footer />
		</>
	);
};
