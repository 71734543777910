import { MainLayout } from 'components/layout/mainLayout';
import { Link } from 'react-router-dom';
import Logo from 'assets/images/logo.svg';
import { isMobile } from 'components/utils';
import Login from 'assets/images/svg/icon_in.svg';
import Person from 'assets/images/svg/icon_person.svg';
import Avatar from 'assets/images/svg/avatar.svg';
import { useAuthState, useAuthDispatch, logout } from 'context';
import { useHistory } from 'react-router-dom';
import Figure from 'assets/images/content/app-figure.svg';
import Nomination from 'assets/images/content/nomination/1.png';
import Nomination1 from 'assets/images/content/nomination/icon_1.png';
import Nomination2 from 'assets/images/content/nomination/icon_2.png';
import Nomination3 from 'assets/images/content/nomination/icon_3.png';
import Nomination1m from 'assets/images/content/nomination/icon_m_1.png';
import Nomination2m from 'assets/images/content/nomination/icon_m_2.png';
import Nomination3m from 'assets/images/content/nomination/icon_m_3.png';
import ErLogo from 'assets/images/content/er-logo.svg';
import MinLogo from 'assets/images/content/mins-rf-logo.svg';
import FondLogo from 'assets/images/content/fond-logo.svg';
import { MainVotes } from 'components/votes';
import Doc from 'assets/images/svg/icon_doc.svg';
import Pdf1 from 'assets/pdf/Polozhenie-o-konkurse.pdf';
import Pdf2 from 'assets/pdf/protokol.pdf';


// import { MainVotes } from 'components/votes';
export const Main = () => {
	const { userDetails } = useAuthState();
	const history = useHistory();
	const dispatch = useAuthDispatch();

	const handleLogout = () => {
		logout(dispatch);
		history.push('/login');
	};

	return (
		<MainLayout>
			<section className='main'>
				<div className='main__bg'>
					<header id='header'>
						<div className='container'>
							<Link className='logo_link' to={'/'}>
								<img src={Logo} alt={'logo'} />
							</Link>
							{history.location.pathname !== '/' && !isMobile && (
								<p className='header_text'>
									<span>Прямая связь</span>
									<br></br>
									<span>платформа сторонников единой россии</span>
								</p>
							)}
							{userDetails?.name ? (
								<div className='details'>
									<Link className='' to={'/profile'}>
										<div>
											<img src={Avatar} alt='ava' />
										</div>
										<div className='details_text'>
											<span>{userDetails?.surname}</span>
											<br></br>
											<span>
												{userDetails?.name} {userDetails?.patronymic}
											</span>
										</div>
									</Link>
									<img
										className='main__icon-in'
										onClick={handleLogout}
										src={Login}
										alt='person'
									/>
								</div>
							) : (
								<Link className='main__btn-in' to={'/login'}>
									<img
										className='main__icon-person'
										src={Person}
										alt='person'
									/>
									Вход / Регистрация
									<img className='main__icon-in' src={Login} alt='person' />
								</Link>
							)}
						</div>
					</header>

					<div className='container'>
						<div className='main__top-block'>
							<h1 className='main__title'>
								Лучший
								<br /> зимний двор
								<br />
								России
							</h1>
							<p className='main__caption'>
								Всероссийский Конкурс лучших
								<br /> практик благоустройства
								<br /> городской среды среди граждан
								<br /> и юридических лиц России
							</p>
						</div>
						<div className='main__bottom-block'>
							<div className='main__left-block'>
								<div className='main__date-text'>
									При поддержке партийных проектов
									<br /> «Школа грамотного потребителя»
									<br />и «Городская среда»
								</div>
								<Link to={'/voting'}>
									<button className='main__btn button blue' type='button'>
                 Все заявки
									</button>
								</Link>
								<Link style={{margin:'0 10px'}} to={'/winners'}>
									<button className='main__btn button blue' type='button'>
									Итоги голосования
									</button>
								</Link>
                <a
								href={Pdf2}
								target={'_blank'}
								rel='noreferrer'
                style={{textDecoration:'none'}}
							>
								<button className='main__btn button file'  type='button'>Протокол жюри</button>
							</a>
							</div>
							<div className='main__right-block'>
								<div className='main__app-figure'>
									<img src={Figure} alt='' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='nomination'>
				<div className='container'>
					<div className='nomination__d-flex'>
						<div className='nomination__block'>
							<h2 className='nomination__title title'>Цель конкурса</h2>
							<p className='nomination__text text-s24'>
								Выявление лучших практик благоустройства городской среды
								<br /> среди граждан и юридических лиц России
							</p>
							<div className='nomination__img'>
								<picture>
									<img src={Nomination} alt='' />
								</picture>
							</div>
						</div>
						<div className='nomination__block'>
							<h2 className='nomination__title title'>Номинации конкурса</h2>
							<div className='nomination__list'>
								<div className='nomination__item'>
									<div className='nomination__icon'>
										<picture>
											<source
												media='(max-width: 768px)'
												srcSet={Nomination1m}
											/>
											<img src={Nomination1} alt='' />
										</picture>
									</div>
									<p className='nomination__text text-s24'>
                  Лучшее зимнее мероприятие во дворе
									</p>
								</div>
								<div className='nomination__item'>
									<div className='nomination__icon'>
										<picture>
											<source
												media='(max-width: 768px)'
												srcSet={Nomination2m}
											/>
											<img src={Nomination2} alt='' />
										</picture>
									</div>
									<p className='nomination__text text-s24'>
									Лучшая зимняя дворовая инфраструктура
									</p>
								</div>
								<div className='nomination__item'>
									<div className='nomination__icon'>
										<picture>
											<source
												media='(max-width: 768px)'
												srcSet={Nomination3m}
											/>
											<img src={Nomination3} alt='' />
										</picture>
									</div>
									<p className='nomination__text text-s24'>Лучшее зимнее оформление двора</p>
								</div>
							</div>
						</div>
					</div>
					<div className='nomination__bottom'>
						<p className='nomination__desc'>
							Количество победителей в каждой номинации определяется решением
							организационного комитета
						</p>
						<p className='nomination__desc'>
							Организационный комитет по предложению жюри Конкурса может
							учредить дополнительные номинации, которые позволят отметить
							выдающиеся заслуги участников, выходящие за тематику номинаций
							Конкурса.
						</p>

						<div className='nomination__files'>
							<a
								href={Pdf1}
								target={'_blank'}
								rel='noreferrer'
								className='nomination__file'
							>
								<span>Положение о конкурсе</span>
								<img src={Doc} alt='' />
							</a>
						</div>
					</div>
				</div>
			</section>

			<MainVotes />


			<div className='container'>
				<div className='calendar__banner'>
					<div className='calendar__banner-text'>
						<h3>Лучший зимний двор России</h3>
						<span>
            ГОЛОСОВАНИЕ ЗАВЕРШЕНО. ИТОГИ КОНКУРСА БУДУТ ПОДВЕДЕНЫ ПОЗДНЕЕ
						</span>
					</div>
					<Link to={'/voting'}>
						<button className='main__btn button' type='button'>
           СМОТРЕТЬ ЗАЯВКИ
						</button>
					</Link>
				</div>
			</div>

			<section className='calendar'>
				<div className='container'>
					<h2 className='calendar__title title'>Календарь конкурса</h2>
					<div className='calendar__wrap'>
						<div className='calendar__card'>
							<div className='calendar__card-title text-s24'>
								старт <br /> конкурса
							</div>
							<div className='calendar__card-date text-s24'>
								<span>28</span> <br /> декабря
							</div>
						</div>
						<div className='calendar__card'>
							<div className='calendar__card-title text-s24'>
								окончание <br />
								приема заявок
							</div>
							<div className='calendar__card-date text-s24'>
								<span>28</span> <br /> февраля 2023 г.
							</div>
						</div>
						<div className='calendar__card'>
							<div className='calendar__card-title text-s24'>
								народное <br /> голосование
							</div>
							<div className='calendar__card-date text-s24'>
								до <span>15</span> <br /> марта 2023 г.
							</div>
						</div>
						<div className='calendar__card'>
							<div className='calendar__card-title text-s24'>
								подведение <br /> итогов
							</div>
							<div className='calendar__card-date text-s24'>
								с <span>16</span> <br /> марта 2023 г.
							</div>
						</div>
						<div className='calendar__card'>
							<div className='calendar__card-title text-s24'>
								награждение <br /> победителей
							</div>
							<div className='calendar__card-date text-s24'>
								март <br /> апрель
								<br /> 2023 г.
							</div>
						</div>
					</div>
				</div>
			</section>




			<section className='sponsor'>
				<div className='container'>
					<div className='sponsor__wrap'>
						<div className='sponsor__block'>
							<h3 className='sponsor__title text-s24'>Организатор</h3>
							<div className='sponsor__row sponsor__row_first'>
								<div className='sponsor__item sponsor__item_row'>
									<div className='sponsor__logo'>
										<img src={ErLogo} alt='' />
									</div>
									<div className='sponsor__logo-text'>
										Всероссийская политическая <br /> партия
										«Единая&nbsp;Россия»
									</div>
								</div>
							</div>
						</div>
						<div className='sponsor__block'>
							<h3 className='sponsor__title text-s24'>Партнеры</h3>
							<div className='sponsor__row sponsor__row_second'>
								<div className='sponsor__logo'>
									<img src={MinLogo} alt='' />
								</div>
								<div className='sponsor__logo'>
									<img src={FondLogo} alt='' />
								</div>

							</div>
						</div>
					</div>
				</div>
			</section>
		</MainLayout>
	);
};
