// import { errorRegisterToast, errorToast } from "components/notifications";


export const validationPhone = (values) => {

	const errors = {};
	if (!values.phone || values.phone === true) {
		errors.phone = 'Обязательное поле';
	}
	if (values.phone && values.phone.length < 18) {
		errors.phone = 'Не правильный формат телефона';
	}
  if (!values.personal_data_confirmation) {
		errors.personal_data_confirmation = 'Обязательное поле';
	}
	return errors;
};

export const validationSms = (values) => {
	const errors = {};
	if (!values.sms) {
		errors.sms = 'Обязательное поле';
	}

	if (values.sms && values.sms.length < 6) {
		errors.sms = 'Обязательное поле';
	}

	return errors;
};

export const validationProfile = (values) => {
	const errors = {};
	const text = /^[а-яА-ЯёЁ -]+$/;
	if (!values.name) {
		errors.name = 'Обязательное поле';
	}
	if (values.name && !text.test(values.name)) {
		errors.name = 'Только русские буквы';
	}
	if (!values.surname) {
		errors.surname = 'Обязательное поле';
	}
	if (values.surname && !text.test(values.surname)) {
		errors.surname = 'Только русские буквы';
	}
	if (!values.birthday) {
		errors.birthday = 'Обязательное поле';
	}
	if (values.birthday && values.birthday === '') {
		errors.birthday = 'Обязательное поле';
	}
	if (!values.email) {
		errors.email = 'Обязательное поле';
	}
	if (
		values.email &&
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = 'Не верный адрес электронной почты';
	}

	if (!values.address) {
		errors.address = 'Обязательное поле';
	}

	if (!values.region_id) {
		errors.region_id = 'Обязательное поле';
	}
	if (!values.district_id) {
		errors.district_id = 'Обязательное поле';
	}

	return errors;
};

export const validationProfile2 = (values) => {
	const errors = {};
	const text = /^[а-яА-ЯёЁ -]+$/;
	if (!values.name) {
		errors.name = 'Обязательное поле';
	}
	if (values.name && !text.test(values.name)) {
		errors.name = 'Только русские буквы';
	}
	if (!values.surname) {
		errors.surname = 'Обязательное поле';
	}
	if (values.surname && !text.test(values.surname)) {
		errors.surname = 'Только русские буквы';
	}
	if (values.patronymic && !text.test(values.patronymic)) {
		errors.patronymic = 'Только русские буквы';
	}
	if (!values.birthday) {
		errors.birthday = 'Обязательное поле';
	}
	if (!values.email) {
		errors.email = 'Обязательное поле';
	}
	if (
		values.email &&
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
	) {
		errors.email = 'Не верный адрес электронной почты';
	}

	if (!values.address) {
		errors.address = 'Обязательное поле';
	}

	if (!values.region_id?.value) {
		errors.region_id = 'Обязательное поле';
	}
	if (!values.district_id) {
		errors.district_id = 'Обязательное поле';
	}

	return errors;
};

export const validateNomination = (values) => {
	const errors = {};

	const text = /^[а-яА-ЯёЁ -]+$/;
	// const textWithSymbols = /^[а-яА-ЯёЁ -<>""'']+$/;
	if (!values.full_address) {
		errors.full_address = 'Обязательное поле';
	}
	if (!values.contact_phone) {
		errors.contact_phone = 'Обязательное поле';
	}
  if (!values.contact_email) {
		errors.contact_email = 'Обязательное поле';
	}
  if (
		values.contact_email &&
		!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.contact_email)
	) {
		errors.email = 'Не верный адрес электронной почты';
	}
  if (!values.municipality) {
		errors.municipality = 'Обязательное поле';
	}
  if (!values.contact_name) {
		errors.contact_name = 'Обязательное поле';
	}
  if (values.contact_name && !text.test(values.contact_name)) {
		errors.contact_name = 'Только русские буквы';
	}
  if (!values.contact_surname) {
		errors.contact_surname = 'Обязательное поле';
	}
  if (values.contact_surname && !text.test(values.contact_surname)) {
		errors.contact_surname = 'Только русские буквы';
	}
	if (!values.region_id) {
		errors.region_id = 'Обязательное поле';
	}
	return errors;
};

export const validateText = (value) => {
	let errorMessage;
	if (!value) {
		errorMessage = 'Обязательное поле';
	}
	return errorMessage;
};

export const validateObj = (value) => {

	let errorMessage;
	if (!value || !value.name) {
		errorMessage = 'Обязательное поле';
	}
	return errorMessage;
};
