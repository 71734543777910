/*eslint no-unused-vars: ["error", { "destructuredArrayIgnorePattern": "^_" }]*/
import React from 'react';
import Select from 'react-select';
import { useField, Field } from 'formik';

export const FormSelect = (props) => {
	const [field, _meta, helpers] = useField(props.field.name);

	return (
		<>
			<Select
				name={props.name}
				value={field.value}
				className={props.className}
				placeholder={props.placeholder}
				onChange={(value) => {
					helpers.setValue(value);
					props.onChange(value.value.toString());
				}}
				options={props.options}
				onBlur={() => helpers.setTouched(true)}
			/>
		</>
	);
};

export const TextField = (props) => {
	const [field, _meta, helpers] = useField(props.name);
	return (
		<div key={props.name} className='profile__input-block'>
			<label htmlFor={props.name}>{props.label}</label>
			<Field
				className={props.className}
				id={props.name}
				name={props.name}
				type='text'
				placeholder={props.placeholder}
				onChange={props.onChange}
				value={field.value}
				onBlur={() => helpers.setTouched(true)}
			/>

		</div>
	);
};

export const EssayField = (props) => {
	const [_field, _meta, helpers] = useField(props.name);
	return (
		<div className='profile__input-block'>
			<label htmlFor={props.name}>Эссе заявки (файлы doc, pdf)</label>
			<label htmlFor={props.name} className={props.className}>
				<span className='profile__input-file-text'>Прикрепить</span>
				<Field
					id={props.name}
					name={props.name}
					type='file'
					hidden
					value={''}
					accept='.doc,.docx,.pdf'
					placeholder='Ссылка'
					onChange={props.onChange}
					// value={field.value}
					onBlur={() => helpers.setTouched(true)}
				/>
			</label>
			{props.value?.name && (
				<div className='images_wrap'>
					<div
						key={props.value.name}
						className='profile__error'
						style={{
							color: 'black',
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<span>{props.value.name}</span>
						<span
							style={{
								color: 'red',
								fontSize: '16px',
								cursor: 'pointer',
							}}
							onClick={props.onclick}
						>
							X
						</span>
					</div>
				</div>
			)}
		</div>
	);
};

export const SelectField = (props) => {
	return (
		<div
			key={props.name}
			className='profile__input-block'
			data-select2-id='select2-data-24-t573'
		>
			<label htmlFor={props.name}>{props.label}</label>
			<Field
				name={props.name}
				className={props.className}
				component={FormSelect}
				placeholder='Выберите из списка'
				value={props.values}
				blur={props.handleBlur}
				onChange={props.onChange}
				options={props.options.map((e, index) => {
					return {
						value: index,
						label: e,
					};
				})}
			/>
			{/* <span className='authorization__error'>
				{errors.nomination && touched.nomination && errors.nomination}
			</span> */}
		</div>
	);
};

export const ImagesField = (props) => {
	return (
		<div className='profile__input-block'>
			<label htmlFor='images'>Фотографии (формат jpg, png)</label>
			<div className='profile__input-block-wrap'>
				<label
					className='profile__input-file'
					htmlFor={props.name}
					style={{ width: '100%' }}
				>
					<span className='profile__input-file-text'>Прикрепить</span>
					<Field
						className={props.className}
						id={props.name}
						hidden
						accept={'.jpg,.png,.pdf'}
						name={props.name}
						type={'file'}
						value={''}
						placeholder='Ссылка'
						onChange={props.onchange}
					/>
					{/* <span className='authorization__error'>
						{errors.images && errors.images}
					</span> */}
				</label>
				{props.value && props.value.length > 0 && (
					<div className='images_wrap'>
						{props.value.map((e, index) => {
							return (
								<>
									<div
										key={index}
										className='profile__error'
										style={{
											color: 'black',
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<span>{e.name}</span>
										<span
											style={{
												color: 'red',
												fontSize: '16px',
												cursor: 'pointer',
											}}
											onClick={() => props.onclick(e.name)}
										>
											X
										</span>
									</div>
								</>
							);
						})}
					</div>
				)}
			</div>
		</div>
	);
};
