import React from 'react';
import Modal from 'react-modal';
import Like from 'assets/images/svg/like.svg'

Modal.setAppElement('#root');

export const SuccessModal = ({
	isOpen,
	setIsOpen,
  votesCount
}) => {
	const clearModalState = () => {
		setIsOpen(false);
	};

	const customStyles = {
		overlay: {
			backgroundColor: 'rgb(0,0,0,0.5)',
			display: 'flex',
			alignItems: 'center',
		},
		content: {
			position: 'unset',
			backgroundColor: 'white',
			height: 'fit-content',
			margin: '0 auto',
			textAlign: 'center',
			top: '0',
			bottom: '0',
			transform: 'unset',
			padding: '25px',
		},
	};

	const closeModal = () => clearModalState();


	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={closeModal}
			className={'submit_modal'}
			style={customStyles}
			contentLabel='Example Modal'
			shouldCloseOnOverlayClick={false}
		>
			<div>
				<h2>Спасибо!</h2>
				<h2>Ваш голос учтён</h2>
        <div><img src={Like} alt='' /><span className='like_text'>{votesCount}</span></div>
				<button onClick={() => closeModal()} className='main__btn btn_vote button' style={{margin:'0 auto'}}>Понятно</button>
			</div>
		</Modal>
	);
};
